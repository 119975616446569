import { useEffect } from 'react'

/**
 * Close Special Offers Page
 * https://simtechdev.atlassian.net/browse/MARKETING-5646
 */

export default function SpecialOffers() {

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.replace('/cs-cart/licenses/')
    }
  }, [])

  return (
    null
  )
}
